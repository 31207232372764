import ProductLink from "./ProductLink";
import { connect } from "react-redux";

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
		productsVisibility: state.productsVisibility,
		strategy: state.partner.strategy,
		source: state.marketing.source,
		utm: {
			medium: state.marketing?.utmMedium,
			source: state.marketing?.utmSource,
			campaign: state.marketing?.utmCampaign,
			content: state.marketing?.utmContent,
			term: state.marketing?.utmTerm,
		},
	};
};

export default connect(mapStateToProps)(ProductLink);
