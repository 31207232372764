import { createSelector } from "reselect";
import { zonedTimeToUtc } from "date-fns-tz";
import isValid from "date-fns/isValid";
import { getMerchPreviewStartDate } from "app/pages/Merchandising/merchandisingSelector";
import { isBetweenDates } from "app/utils/utils";

export const getPushNotifs = state =>
	state.pushNotification && state.pushNotification.pushNotifications;

const isPushNotificationExpired = (pushNotification, previewDateTimestamp) => {
	const todayTimestamp = new Date().getTime();
	const pushNotificationEndAtTimestamp = new Date(pushNotification.endAt).getTime();
	const pushNotificationStartAtTimestamp = new Date(pushNotification.launchAt).getTime();
	if (isValid(previewDateTimestamp)) {
		return !(
			previewDateTimestamp >= pushNotificationEndAtTimestamp ||
			previewDateTimestamp < pushNotificationStartAtTimestamp
		);
	}

	// do not return expired push notification
	return pushNotificationEndAtTimestamp >= todayTimestamp;
};

export const getPushNotifications = createSelector(
	[getPushNotifs, getMerchPreviewStartDate],
	(pushNotifications = [], previewStartDate) => {
		// previewStartDate est valorisé par la query param ?preview=YYYYMMDD
		const previewDateTimestamp = zonedTimeToUtc(previewStartDate || new Date(), "YYYYMMDD");

		if (previewDateTimestamp) {
			return []
				.concat(pushNotifications)
				.filter(pushNotification =>
					isPushNotificationExpired(pushNotification, previewDateTimestamp)
				);
		}
		return []
			.concat(pushNotifications)
			.filter(pushNotification =>
				isBetweenDates(pushNotification.launchAt, pushNotification.endAt)
			);
	}
);
