import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedPlural } from "react-intl";
import AmountContainer from "app/pages/.shared/AmountContainer";
import { OFFER_PRICE_TYPES } from "app/constants";
import { fromPriceTypeShape } from "app/utils/propTypes";
import IconPlane2 from "app/pages/.shared/IconPlane2";
import "./ProductCardFromPrice.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const ProductCardFromPrice = ({
	travelByTrainAvailable,
	value,
	pricePrefix,
	priceSuffix,
	publicPrice,
	offerPriceType,
	includeOfferWithFlight,
	publicPricePrefix,
	publicPriceSuffix,
	duration,
	showFromInfo = true,
}) => {
	return (
		<div className="product-card-from-price">
			<div className="product-card-from-price__price">
				<div
					className="product-card-from-price__value"
					data-testid="product-card-from-price-value"
				>
					<AmountContainer
						amount={Number(value)}
						prefix={pricePrefix}
						suffix={priceSuffix}
					/>
				</div>

				{publicPrice > 0 && (
					<div
						className="product-card-from-price__public-price"
						data-testid="product-card-from-price-public-price"
					>
						<AmountContainer
							amount={publicPrice}
							prefix={publicPricePrefix}
							suffix={publicPriceSuffix}
						/>
					</div>
				)}
			</div>
			{showFromInfo && (
				<Typography
					variant={TYPOGRAPHY_VARIANTS.REGULAR}
					variantMD={TYPOGRAPHY_VARIANTS.REGULAR}
				>
					<div className="product-card-from-price__info">
						<FormattedPlural
							value={duration}
							one={
								<FormattedMessage
									id="general.duration.singular"
									values={{ duration: duration }}
								/>
							}
							other={
								<FormattedMessage
									id="general.duration.plural"
									values={{ duration: duration }}
								/>
							}
						/>
						,
						<div className="product-card-from-price__flight">
							{offerPriceType ===
								OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM_WITH_FLIGHT &&
								includeOfferWithFlight === true && (
									<div className="product-card-from-price__flight--included">
										{travelByTrainAvailable ? (
											<FormattedMessage id="general.included.transport" />
										) : (
											<FormattedMessage id="general.included.flight" />
										)}
										{travelByTrainAvailable === false && (
											<IconPlane2
												width={15}
												height={15}
												className="icon--flight"
											/>
										)}
									</div>
								)}

							{offerPriceType === OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM &&
								includeOfferWithFlight === true &&
								(!travelByTrainAvailable ? (
									<FormattedMessage id="general.excluded.flight" />
								) : (
									<FormattedMessage id="general.excluded.transport" />
								))}
						</div>
					</div>
				</Typography>
			)}
		</div>
	);
};

ProductCardFromPrice.propTypes = {
	travelByTrainAvailable: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	includeOfferWithFlight: PropTypes.bool,
	pricePrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	priceSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	publicPricePrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	publicPriceSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	publicPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	offerPriceType: fromPriceTypeShape,
	duration: PropTypes.number,
	showFromInfo: PropTypes.bool,
};

export default memo(ProductCardFromPrice);
