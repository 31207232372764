import { memo } from "react";
import RelativeLink from "../RelativeLink";
import PropTypes from "prop-types";
import { PRODUCTS_VISIBILITY, STRATEGY } from "app/constants";
import { stringify, parse } from "qs";
import isEmpty from "lodash/isEmpty";
import { strategyShape } from "app/utils/propTypes";
import { isAbsoluteUrl } from "app/utils/urlDataExtractor";
import { useLocation } from "react-router-dom";
import { parseQueries } from "app/utils/routerUtils";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

const ProductLink = ({
	handleProductClick,
	children,
	openOnNewTab,
	productsVisibility,
	productUri,
	strategy,
	resolution,
	source,
	utm = {},
	query = {},
}) => {
	const isDesktop = resolution === RESOLUTION.LARGE || resolution === RESOLUTION.XLARGE;
	const newTabLink = openOnNewTab
		? {
				target: "_blank",
				rel: "noopener noferrer",
		  }
		: {};

	let productUrl = productUri;
	if (productUri && !isAbsoluteUrl(productUri) && !productUri.startsWith("/")) {
		productUrl = "/" + productUri;
	}
	const { search: searchLocation } = useLocation();
	const { code: codeMerch } = parseQueries(searchLocation);

	let search = {};

	let to = {};

	if (productUrl) {
		const productUrlPart = productUrl.split("?");
		productUrl = productUrlPart[0];
		const productUrlQueries = productUrlPart[1];

		if (productUrlQueries) {
			search = {
				...parse(productUrlQueries, { ignoreQueryPrefix: true }),
			};
		}

		search = {
			...search,
			...query,
		};

		to = {
			pathname: `${productUrl}`,
		};
	}

	// Persistence du mode preview de la listing vers la fiche produit pour la team production
	if (productsVisibility === PRODUCTS_VISIBILITY.PREVIEW) {
		search.preview = PRODUCTS_VISIBILITY.PREVIEW;
	}

	// si on ouvre dans un nouvel onglet, on doit persister le mode preview et la strategy
	if (openOnNewTab) {
		const strategyExists =
			strategy &&
			Object.keys(STRATEGY).some(key => {
				return STRATEGY[key] === strategy;
			});

		// Persistence de la strategy de la listing vers la fiche produit pour la team production
		if (strategyExists && !search.strategy) {
			search.strategy = strategy;
		}
	}

	// Sur tous nos sites, actuellement, lorsqu’on accède à une page merch, on mémorise le merch affiché afin de
	// - pouvoir savoir sur les pages suivantes d’où venait l’utilisateur et attribuer la vente en merch (via la posed’un tag dans la bookingApi)
	// - gérer correctement le lien retour vers le merch depuis la fiche produit
	// Sur mobile cela fonctionne parfaitement car il n’y a pas d’ouverture depuis la page listing merch dans un nouvel onglet.
	// Sur desktop, les 2 points sont KO car l’info du merch vu par le client est mémorisé dans le sessionStorage
	if (codeMerch && isDesktop) {
		search.fromMerch = codeMerch;
	}

	if (source) {
		search.source = source;
	}

	if (utm.medium) {
		search.utm_medium = utm.medium;
	}

	if (utm.source) {
		search.utm_source = utm.source;
	}

	if (utm.campaign) {
		search.utm_campaign = utm.campaign;
	}

	if (utm.content) {
		search.utm_content = utm.content;
	}

	if (utm.term) {
		search.utm_term = utm.term;
	}

	if (!isEmpty(search)) {
		to.search = stringify(search);
	}

	return (
		<RelativeLink
			variant="primary"
			className="product-link"
			{...newTabLink}
			to={to}
			onClick={handleProductClick}
			data-testid={`product-cta-${productUri}`}
		>
			{children}
		</RelativeLink>
	);
};

ProductLink.propTypes = {
	handleProductClick: PropTypes.func,
	openOnNewTab: PropTypes.bool,
	productsVisibility: PropTypes.string,
	strategy: strategyShape,
	productUri: PropTypes.string,
	resolution: PropTypes.string,
	source: PropTypes.string,
	query: PropTypes.object,
	utm: PropTypes.object,
};

export default memo(ProductLink);
