import { Fragment, memo } from "react";
import className from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import IconFacebook from "app/pages/.shared/static/icons/IconFacebook";
import IconInstagram from "app/pages/.shared/static/icons/IconInstagram";

const ICON_HASHMAP = {
	facebook: IconFacebook,
	instagram: IconInstagram,
};

const SocialNetworksList = ({ socialNetworksLinks }) => {
	return (
		<Fragment>
			<div className="social__headline">
				<FormattedMessage id="footer.social.headline" />
			</div>
			<div className="social__items">
				<ul className={className("social-network-list", "social__list")}>
					{Object.entries(socialNetworksLinks).map(
						([socialNetworkName, socialNetworkLink]) => {
							const Icon = ICON_HASHMAP[socialNetworkName];

							if (!Icon) {
								return null;
							}

							return (
								<li
									className={className(
										"social-network-list__item",
										"social__item",
										`social-network-list__item__${socialNetworkName}`
									)}
									key={socialNetworkName}
								>
									<a
										href={socialNetworkLink}
										rel="noopener noreferrer"
										target="_blank"
									>
										<Icon height={20} width={20} />
									</a>
								</li>
							);
						}
					)}
				</ul>
			</div>
		</Fragment>
	);
};

SocialNetworksList.defaultProps = {
	socialNetworksLinks: {},
};

SocialNetworksList.propTypes = {
	socialNetworksLinks: PropTypes.object,
};

export default memo(SocialNetworksList);
