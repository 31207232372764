import PropTypes from "prop-types";
import { memo } from "react";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import "./StepsDescriptionBlock.scss";

const StepsDescriptionBlock = ({ stepsDescriptionList = [], title = "" }) => {
	return (
		stepsDescriptionList.length > 0 && (
			<div className="steps-description-block">
				{title && (
					<Typography
						variant={TYPOGRAPHY_VARIANTS.XL3}
						variantMD={TYPOGRAPHY_VARIANTS.XL5}
						isBold
						className="steps-description-block__title"
					>
						{title}
					</Typography>
				)}
				<div className="steps-description-block__list">
					{stepsDescriptionList.map((step = {}, index) => (
						<div key={index} className="steps-description-block__item">
							<div className="steps-description-block__item-header">
								<div className="steps-description-block__item-index">
									<Typography variant={TYPOGRAPHY_VARIANTS.SMALL} isBold>
										{index + 1}
									</Typography>
								</div>
								<div className="steps-description-block__item-content">
									<Typography
										variant={TYPOGRAPHY_VARIANTS.XL2}
										component="h2"
										isBold
										className="steps-description-block__item-title"
									>
										{step.stepTitle}
									</Typography>
									<Typography
										variant={TYPOGRAPHY_VARIANTS.REGULAR}
										className="steps-description-block__item-description"
									>
										{step.description}
									</Typography>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		)
	);
};

StepsDescriptionBlock.propTypes = {
	title: PropTypes.string,
	stepsDescriptionList: PropTypes.array,
};

export default memo(StepsDescriptionBlock);
