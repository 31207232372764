import { FETCH_PUSH_NOTIFICATIONS_SUCCESS } from "app/pages/.shared/PushNotification/pushNotificationActionTypes";

const initialState = { pushNotifications: [] };

export default (pushNotificationsState = initialState, action) => {
	switch (action.type) {
		case FETCH_PUSH_NOTIFICATIONS_SUCCESS: {
			const data = action?.res?.data || [];
			return { ...pushNotificationsState, pushNotifications: data };
		}

		default:
			return pushNotificationsState;
	}
};
