import { useLayoutEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

/*
 * Pour repositionner en haut de page à chaque nouvelle page
 * @see https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
const ScrollToTop = () => {
	const { pathname } = useLocation();
	const navType = useNavigationType();

	useLayoutEffect(() => {
		const isBack = navType === "POP";

		if (
			!isBack ||
			!(pathname === "/faq" || pathname.includes("listing") || pathname.includes("merch"))
		) {
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 10);
		}
	}, [pathname]);

	return null;
};

export default ScrollToTop;
