import { useEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { fetchPushNotifications } from "app/pages/.shared/PushNotification/pushNotificationActionCreators";
import { connect } from "react-redux";
import get from "lodash/get";
import { PushNotification } from "app/pages/.shared/PushNotification/PushNotification";
import { useLocation, useParams } from "react-router-dom";
import { getPushNotifications } from "app/pages/.shared/PushNotification/pushNotificationSelector";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import { isServerSide, isUserMatchWithType } from "app/utils/utils";
import { useFlagship, useFsFlag } from "@flagship.io/react-sdk";

const PushNotificationComponent = ({
	fetchPushNotifications,
	pushNotifications = [],
	userIsConnected,
}) => {
	const { productUri } = useParams();
	const isFicheProduitPage = Boolean(productUri);
	const location = useLocation();

	const fsShowPushNotification = useFsFlag("push_notification_show", true);
	const { status: fsStatus } = useFlagship();

	useEffect(() => {
		fetchPushNotifications();
	}, []);

	return pushNotifications.map((pushNotification = {}) => {
		const pages = get(pushNotification, "content.pages") || [];
		const checkRoute = pages.find(page => {
			return (
				location.pathname === page ||
				(page === "/:productUri" && isFicheProduitPage) ||
				(page === "/sdp/listing" && location.pathname.includes(page))
			);
		});

		const usersFilterType = pushNotification?.content?.usersFilter;
		const checkUserType = isUserMatchWithType(usersFilterType, userIsConnected);

		return (
			checkUserType &&
			checkRoute &&
			(fsStatus.isSdkReady || (!isServerSide && window.Cypress)) &&
			fsShowPushNotification.getValue() && (
				<div key={pushNotification.name}>
					<PushNotification
						backgroundColor={get(pushNotification, "content.backgroundColor")}
						picto={get(pushNotification, "content.picto")}
						title={get(pushNotification, "content.title")}
						content={get(pushNotification, "content.content")}
						promoCode={get(pushNotification, "content.promoCode")}
						note={get(pushNotification, "content.note")}
						buttonUrl={get(pushNotification, "content.buttonUrl")}
					/>
				</div>
			)
		);
	});
};

PushNotificationComponent.propTypes = {
	fetchPushNotifications: PropTypes.func,
	pushNotifications: PropTypes.arrayOf(PropTypes.object),
	userIsConnected: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		pushNotifications: getPushNotifications(state),
		userIsConnected: isAuthenticated(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ fetchPushNotifications }, dispatch);
};

export const PushNotificationContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(PushNotificationComponent);
