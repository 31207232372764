import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Countdown from "react-countdown-now";

const TimerCountdown = ({ date = 0, onComplete = () => {} }) => {
	return (
		<Countdown
			date={date}
			zeroPadTime={2}
			zeroPadDays={2}
			onComplete={onComplete}
			renderer={({ formatted: { days, hours, minutes, seconds } }) => (
				<div className="auth__countdown" data-testid="auth-countdown">
					{days && days > 0 && (
						<span className="auth__countdown-item" data-testid="auth-countdown-days">
							{days} <FormattedMessage id="countdown.days.short" />
						</span>
					)}
					{hours && hours > 0 && (
						<span className="auth__countdown-item" data-testid="auth-countdown-hours">
							{hours} <FormattedMessage id="countdown.hours.short" />
						</span>
					)}
					{minutes && minutes > 0 && (
						<span className="auth__countdown-item" data-testid="auth-countdown-minutes">
							{minutes} <FormattedMessage id="countdown.minutes.short" />
						</span>
					)}
					{seconds && seconds > 0 && (
						<span className="auth__countdown-item" data-testid="auth-countdown-seconds">
							{seconds} <FormattedMessage id="countdown.seconds.short" />
						</span>
					)}
				</div>
			)}
		/>
	);
};

TimerCountdown.propTypes = {
	date: PropTypes.number,
	onComplete: PropTypes.func,
};

export default TimerCountdown;
