import { connect } from "react-redux";
import { ProductDestinationTitle } from "app/pages/.shared/ProductDestinationTitle/ProductDestinationTitle";

const mapStateToProps = state => {
	return {
		shop: state.shop,
	};
};

export default connect(mapStateToProps)(ProductDestinationTitle);
