import { useCallback, useState } from "react";
import classNames from "classnames";
import get from "lodash/get";
import Collapse, { Panel } from "rc-collapse";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import IconArrowUp from "app/pages/.shared/static/icons/IconArrowUp";
import { htmlSanitize } from "app/utils/sanitize";
import "./PushNotification.scss";

const PushNotificationComponent = ({
	backgroundColor,
	picto,
	title = [],
	content = [],
	promoCode,
	note = [],
	buttonUrl,
}) => {
	const [expanded, toggleExpanded] = useState(false);
	const [animate, setAnimate] = useState(false);

	const onChange = useCallback(() => {
		toggleExpanded(!expanded);
		setAnimate(false);
	}, [expanded]);

	const activeKey = expanded ? "0" : undefined;

	const pushClassname = classNames({
		push: true,
		"push--opened": expanded,
		"push--animate": animate,
	});

	return (
		<div
			className={pushClassname}
			style={{
				backgroundColor: backgroundColor,
			}}
			data-testid="push-notification"
		>
			<Collapse onChange={onChange} activeKey={activeKey}>
				<Panel
					header={
						<div className="push-header">
							<div className="push-header__picto">
								{picto && (
									<i
										className="icon"
										style={{
											backgroundImage: `url(${picto})`,
										}}
									/>
								)}
							</div>
							<div className="push-header__text">
								{title.map(titleLine => (
									<div
										className="push-header__title"
										key={titleLine}
										dangerouslySetInnerHTML={{
											__html: htmlSanitize(titleLine),
										}}
									/>
								))}
							</div>
							<div className="push-header__arrow">
								<IconArrowUp height={20} width={20} />
							</div>
						</div>
					}
				>
					<div className="push__content">
						{content.map(contentLine => (
							<div
								key={contentLine}
								className="push__line push__line__content"
								dangerouslySetInnerHTML={{
									__html: htmlSanitize(contentLine),
								}}
							/>
						))}

						{promoCode && (
							<div className="push__line">
								<div className="push__code">
									<span style={{ userSelect: "none" }}>
										<FormattedMessage id="push.promotion.code.label" /> :{" "}
									</span>
									{promoCode}
								</div>
							</div>
						)}
						{note.map(noteLine => (
							<div className="push__line" key={noteLine}>
								<div className="push__note">{noteLine}</div>
							</div>
						))}
						{buttonUrl && (
							<div className="push__cta" style={{ textAlign: "center" }}>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={get(buttonUrl, "url")}
								>
									{get(buttonUrl, "buttonLabel")}
								</a>
							</div>
						)}
					</div>
				</Panel>
			</Collapse>
		</div>
	);
};

PushNotificationComponent.propTypes = {
	backgroundColor: PropTypes.string,
	picto: PropTypes.string,
	title: PropTypes.arrayOf(PropTypes.string),
	content: PropTypes.arrayOf(PropTypes.string),
	promoCode: PropTypes.string,
	note: PropTypes.arrayOf(PropTypes.string),
	buttonUrl: PropTypes.shape({ url: PropTypes.string, buttonLabel: PropTypes.string }),
};

export const PushNotification = PushNotificationComponent;
