import PropTypes from "prop-types";
import ReasonToBookCarousel from "app/pages/.shared/ReasonsToBook/ReasonToBookCarousel/ReasonToBookCarousel";
import { messagePropType } from "app/utils/propTypes";
import { memo } from "react";
import "./ReasonToBookBlock.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const ReasonToBookBlock = ({ reasonToBookList = [], title = [] }) => {
	return (
		reasonToBookList.length > 0 && (
			<div className="reason-to-book-block" data-testid="reason-to-book-block">
				{title && (
					<Typography
						variant={TYPOGRAPHY_VARIANTS.XL3}
						variantMD={TYPOGRAPHY_VARIANTS.XL4}
						isBold
						className="reason-to-book-block__title"
						component="h2"
					>
						{title}
					</Typography>
				)}
				<ReasonToBookCarousel reasonToBookList={reasonToBookList} />
			</div>
		)
	);
};

ReasonToBookBlock.propTypes = {
	title: messagePropType,
	reasonToBookList: PropTypes.array,
};

export default memo(ReasonToBookBlock);
