import { memo, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import ReasonToBook from "app/pages/.shared/ReasonsToBook/ReasonToBook/ReasonToBook";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import appGlobalsContext from "app/AppGlobalsContext";
import "./ReasonToBookCarousel.scss";
import CarouselArrow, {
	ARROW_DIRECTION,
} from "app/pages/.shared/Carousel/CarouselArrow/CarouselArrow";
import CarouselDots from "app/pages/.shared/Carousel/CarouselDots/CarouselDots";

const SLIDES_PER_VIEW = 4;

const SLIDES_NUMBER_PER_RESOLUTION = {
	[RESOLUTION.LARGE]: SLIDES_PER_VIEW,
	[RESOLUTION.XLARGE]: SLIDES_PER_VIEW,
	[RESOLUTION.MEDIUM]: 2.3,
	[RESOLUTION.SMALL]: 1.5,
};

const ReasonToBookCarousel = ({ reasonToBookList = [] }) => {
	const [swiper, setSwiper] = useState(null);
	const [currentSlide, setCurrentSlide] = useState(0);

	const { resolution } = useContext(appGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const shouldHideNavigation = reasonToBookList.length < SLIDES_PER_VIEW;

	const updateSwiper = swiper => {
		setSwiper(swiper);
	};

	const slidePrev = () => {
		swiper.slidePrev();
	};

	const slideNext = () => {
		swiper.slideNext();
	};

	const swiperParams = {
		shouldSwiperUpdate: true,
		getSwiper: updateSwiper,
		slidesPerView: shouldHideNavigation
			? reasonToBookList?.length
			: SLIDES_NUMBER_PER_RESOLUTION[resolution],
		spaceBetween: 24,
		loop: !isMobile,
	};

	useEffect(() => {
		if (swiper && isMobile) {
			swiper.on("snapIndexChange", () => {
				if (!isNaN(swiper?.snapIndex)) {
					const currentSlide = swiper?.snapIndex;
					setCurrentSlide(currentSlide);
				}
			});
		}

		return () => {
			if (swiper && isMobile) {
				swiper.off("snapIndexChange");
			}
		};
	}, [swiper, isMobile]);

	return (
		<div className="reason-to-book-carousel">
			{/* Navigations have to be placed before <Swiper> because double click on arrows will select another element above */}
			{swiper && !shouldHideNavigation && (
				<>
					{isMobile ? (
						<CarouselDots
							currentSlide={currentSlide}
							totalSlides={reasonToBookList?.length}
							size={8}
						/>
					) : (
						<>
							<div className="reason-to-book-carousel__button reason-to-book-carousel__button--next">
								<CarouselArrow
									direction={ARROW_DIRECTION.RIGHT}
									onClick={slideNext}
								/>
							</div>
							<div className="reason-to-book-carousel__button reason-to-book-carousel__button--previous">
								<CarouselArrow
									direction={ARROW_DIRECTION.LEFT}
									onClick={slidePrev}
								/>
							</div>
						</>
					)}
				</>
			)}
			<Swiper {...swiperParams}>
				{reasonToBookList.map((reasonToBook, index) => {
					return (
						<div key={index} className="reason-to-book-carousel__item">
							<ReasonToBook
								title={reasonToBook?.title}
								description={reasonToBook?.description}
								picto={reasonToBook?.picto}
							/>
						</div>
					);
				})}
			</Swiper>
		</div>
	);
};

ReasonToBookCarousel.propTypes = {
	reasonToBookList: PropTypes.array,
};

export default memo(ReasonToBookCarousel);
