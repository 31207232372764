import PropTypes from "prop-types";
import {
	arrow,
	FloatingPortal,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
} from "@floating-ui/react";
import { useContext, useRef, useState } from "react";
import "./FloatingButton.scss";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import useClickAway from "app/utils/hooks/useClickAway";

const FloatingButton = ({
	referenceComponent = false,
	floatingContent,
	position,
	onClick = () => {},
	onClickAway = () => {},
	popoverClassName,
	floatingOffsetMobile = [0, 0],
	floatingOffsetDesk = [0, 0],
	zIndexValue,
	referenceButtonClassName,
	displayCrossIcon = false,
	floatingContainerClassName,
	dismissOnFloatingClick = false,
}) => {
	const [open, setOpen] = useState(false);
	const floatingContentRef = useRef();
	const arrowRef = useRef();

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const {
		x,
		y,
		refs,
		strategy,
		placement,
		context,
		middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
	} = useFloating({
		placement: position,
		strategy: "absolute",
		open,
		onOpenChange: setOpen,
		middleware: [offset(10), shift(), arrow({ element: arrowRef })],
	});

	// ancestorScroll = true => disappear when the user scroll the parent
	const dismiss = useDismiss(context, { ancestorScroll: true });
	const click = useClick(context);
	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

	const handleClick = () => {
		if (dismissOnFloatingClick) {
			setOpen(false);
		}
	};

	useClickAway(floatingContentRef, onClickAway);

	const staticSide = {
		top: "bottom",
		right: "left",
		bottom: "top",
		left: "right",
	}[placement.split("-")[0]];
	const arrowPositionX = arrowX - (isMobile ? floatingOffsetMobile[0] : floatingOffsetDesk[0]);
	const arrowPositionY = arrowY + (isMobile ? floatingOffsetMobile[1] : floatingOffsetDesk[1]);

	const arrowStyle = {
		left: arrowX !== null ? `${arrowPositionX}px` : "",
		top: arrowY !== null ? `${arrowPositionY}px` : "",
		right: "",
		bottom: "",
		[staticSide]: "-5px",
	};

	if (staticSide === "top") {
		arrowStyle["border-right-color"] = "white";
		arrowStyle["border-bottom-color"] = "white";
	} else if (staticSide === "right") {
		arrowStyle["border-bottom-color"] = "white";
		arrowStyle["border-left-color"] = "white";
	} else if (staticSide === "bottom") {
		arrowStyle["border-left-color"] = "white";
		arrowStyle["border-top-color"] = "white";
	} else if (staticSide === "left") {
		arrowStyle["border-top-color"] = "white";
		arrowStyle["border-right-color"] = "white";
	}
	return (
		<div className={`floating-button ${floatingContainerClassName ?? ""}`} onClick={onClick}>
			<span
				data-testid="floating-button-ref"
				ref={refs.setReference}
				{...getReferenceProps({})}
				className={referenceButtonClassName}
			>
				{referenceComponent}
			</span>
			<FloatingPortal>
				{open && (
					<div
						ref={refs.setFloating}
						{...getFloatingProps({
							style: {
								position: strategy,
								left:
									x +
										(isMobile
											? floatingOffsetMobile[0]
											: floatingOffsetDesk[0]) ?? "",
								top:
									y +
										(isMobile
											? floatingOffsetMobile[1]
											: floatingOffsetDesk[1]) ?? "",
								zIndex: zIndexValue ? zIndexValue : 4,
							},
						})}
						{...popoverClassName && { className: popoverClassName }}
					>
						<div
							ref={floatingContentRef}
							className="floating-button__content"
							onClick={handleClick}
						>
							{floatingContent}
							{displayCrossIcon && <i className="icon icon--cross-dark" />}
						</div>
						<div className="floating-button__arrow" ref={arrowRef} style={arrowStyle} />
					</div>
				)}
			</FloatingPortal>
		</div>
	);
};

FloatingButton.propTypes = {
	position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
	referenceComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	floatingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onClick: PropTypes.func,
	onClickAway: PropTypes.func,
	popoverClassName: PropTypes.string,
	floatingOffsetDesk: PropTypes.array,
	floatingOffsetMobile: PropTypes.array,
	zIndexValue: PropTypes.number,
	referenceButtonClassName: PropTypes.string,
	displayCrossIcon: PropTypes.bool,
	floatingContainerClassName: PropTypes.string,
	dismissOnFloatingClick: PropTypes.bool,
};

export default FloatingButton;
