import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { OFFER_PRICE_TYPES } from "app/constants";
import SaveUpTo from "app/pages/FicheProduit/components/Deal/SaveUpTo";
import ProductCardFromPrice from "app/pages/.shared/ProductCard/ProductCardFromPrice";
import { fromPriceTypeShape } from "app/utils/propTypes";

const ProductCardPricing = ({
	hour,
	day,
	value,
	duration,
	publicPrice,
	type,
	isRental,
	isWithHotel = true,
	includeOfferWithFlight,
	travelByTrainAvailable,
}) => {
	const isDealExpired = hour === 0 && day === 0;

	let priceNode = false;

	switch (type) {
		case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_SAVE_UP_TO:
			priceNode = <SaveUpTo value={value} />;
			break;
		case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM:
			priceNode = (
				<ProductCardFromPrice
					travelByTrainAvailable={travelByTrainAvailable}
					includeOfferWithFlight={includeOfferWithFlight}
					value={value}
					pricePrefix={<FormattedMessage id="general.from.label" />}
					priceSuffix={
						isRental ? (
							<FormattedMessage id="general.amount.per.accommodation.suffix.label.short" />
						) : (
							<FormattedMessage id="general.amount.per.person.suffix.label.short" />
						)
					}
					duration={duration}
					publicPrice={publicPrice}
					publicPricePrefix={<FormattedMessage id="general.instead.of.price" />}
					offerPriceType={type}
					isWithHotel={!isRental && isWithHotel}
					showFromInfo
				/>
			);
			break;
		case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM_WITH_FLIGHT:
			priceNode = (
				<ProductCardFromPrice
					travelByTrainAvailable={travelByTrainAvailable}
					includeOfferWithFlight={true}
					value={value}
					pricePrefix={<FormattedMessage id="general.from.label" />}
					priceSuffix={
						isRental ? (
							<FormattedMessage id="general.amount.per.accommodation.suffix.label.short" />
						) : (
							<FormattedMessage id="general.amount.per.person.suffix.label.short" />
						)
					}
					duration={duration}
					publicPrice={publicPrice}
					publicPricePrefix={<FormattedMessage id="general.instead.of.price" />}
					offerPriceType={type}
					isWithHotel={!isRental && isWithHotel}
					showFromInfo
				/>
			);
			break;
		case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_EXCLUSIVE:
			priceNode = (
				<div className="product-deal__exclusive">
					<FormattedMessage id="general.exclusive.label" />
				</div>
			);
			break;
		default:
			priceNode = false;
	}

	return (
		<div className="product-card-pricing">
			{!isDealExpired ? (
				priceNode
			) : (
				<div className="product-card-pricing__status">
					<FormattedMessage id="product.deal.expired.label" />
				</div>
			)}
		</div>
	);
};

ProductCardPricing.propTypes = {
	travelByTrainAvailable: PropTypes.bool,
	duration: PropTypes.number,
	type: fromPriceTypeShape,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	publicPrice: PropTypes.number,
	hour: PropTypes.number,
	day: PropTypes.number,
	isRental: PropTypes.bool,
	isWithHotel: PropTypes.bool,
	includeOfferWithFlight: PropTypes.bool,
};

export default memo(ProductCardPricing);
