import PropTypes from "prop-types";
import { memo } from "react";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { connect } from "react-redux";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";
import { useAnalytics } from "app/utils/analytics/useAnalytics";

const MAX_WIDTHS = {
	small: 768,
	medium: 978,
	large: 1440,
	xlarge: 1440,
};

const IMG_STYLES = {
	width: "100%",
	display: "block",
	height: "100%",
};

/**
 * Version simplifiée du ProgressiveImg sans la gestion du LazyLoading et de l'effet de transistion du flou vers l'image finale
 * UItilisé dans les carousels du site pour éviter les images floues qui ne se chargent pas
 */
const SimpleProgressiveImg = ({
	alt,
	src,
	quality,
	resolution,
	sizes = {},
	productUri,
	cloudinaryOptions = [],
}) => {
	const { track } = useAnalytics();
	const cloudinarify = () => {
		let cloudinaryConfig = [];

		const size = sizes && sizes[resolution];

		if (size && size.width) {
			cloudinaryConfig.push(`w_${size.width}`);
		} else {
			cloudinaryConfig.push(`w_${MAX_WIDTHS[resolution]}`);
		}

		if (size && size.height) {
			cloudinaryConfig.push(`h_${size.height},c_fill,g_center`);
		}

		if (src) {
			let options = cloudinaryConfig;

			if (cloudinaryOptions) {
				options = cloudinaryConfig.concat(cloudinaryOptions);
			}
			return addOptionsToCloundinaryURL(src, options, {
				quality: quality,
			});
		}

		return "";
	};

	const source = cloudinarify();

	return (
		resolution !== RESOLUTION.UNKNOWN &&
		sizes[resolution] !== undefined && (
			<img
				onError={() =>
					track("image_not_loaded", {
						url: source,
						uri: productUri,
					})
				}
				style={IMG_STYLES}
				alt={alt}
				src={source}
			/>
		)
	);
};

SimpleProgressiveImg.propTypes = {
	sizes: PropTypes.shape({
		small: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		medium: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		large: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		xlarge: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
	}),
	alt: PropTypes.string,
	src: PropTypes.string,
	resolution: PropTypes.string,
	quality: PropTypes.string,
	productUri: PropTypes.string,
	cloudinaryOptions: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(memo(SimpleProgressiveImg));
